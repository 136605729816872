import React,{useEffect,useState,useRef} from 'react'
import "../style/contactUs.less"
import Header from '../components/header'
import Footer from "../components/footer"
export default function ContactUs() {
    const contactTitle = useRef(null)
    const [contactShadow,setContactShadow] = useState(false)
    useEffect(()=>{
        const observer = new IntersectionObserver(entries => {
            setContactShadow(entries[0].isIntersecting)
        }, {
            threshold: [1]
        })
        observer.observe(contactTitle.current)
    })
  return (
    <>
    <div className='contactUs'>
        <Header/>
        <div className="title">
            <h1 className={contactShadow?"shadow":""} ref={contactTitle}>Contact</h1>
            <h3>info@sightlineinnovation.com</h3>
        </div>
        <div className='contactUs_content'>
            <ul>
                <li>
                    <div>
                        <h6>Winnipeg</h6>
                        <h3>1-416-628-7095</h3>
                        <p>Avotu 9-6,<br/>
                            Riga, LV-1011, Latvia</p>
                    </div>
                    {/* <div>
                        <h6>Winnipeg</h6>
                        <h3>1-416-628-7095</h3>
                        <p>Avotu 9-6,<br/>
                            Riga, LV-1011, Latvia</p>
                    </div> */}
                </li>
                <li>
                    <div className='input'>
                        <input type="text" placeholder='Your Name' />
                    </div>
                    <div className='input'>
                        <input type="text" placeholder='Company Name' />
                    </div>
                    <div className='input'>
                        <input type="text" placeholder='Phone number (Optional)' />
                    </div>
                    <div className='input'>
                        <input type="text" placeholder='E-mail' />
                    </div>
                    <div className='input'>
                        <input type="text" placeholder='Comment (Optional)' />
                    </div>
                    <div className='send_request'>
                        <div>Send Request</div>
                        <p>By clicking the Send Request button you agree to our Privacy Policy terms</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    
    <Footer/>
    </>
  )
}
